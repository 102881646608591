<template>
  <div class="flex-grow-1 w-100 d-flex align-items-center">
    <div class="bg-composed-wrapper--content py-5">
      <div class="container">
        <div class="row">
          <!-- <div
            class="
              col-lg-8
              pl-0
              flex-column
              align-items-start
              justify-content-center
              text-center text-lg-left
              pr-5
            "
            style="background: #A3997E;"
          >

            <img
              src="@/assets/img/login-elearn.png"
              class="img-fluid d-none d-lg-flex"
            />
          </div> -->

          <div
            class="
              col-12
              offset-1
              col-lg-12
              offset-lg-0
              pr-0
              d-flex
              flex-column
              justify-content-center
            "
          >
            <b-card class="text-black mt-3 my-4" style="box-shadow: none;">
              <LogoSvg class="w-50 mx-auto d-block img-fluid mb-3" />

              <h1 class="display-3 mb-3 text-primary font-weight-bold text-center">
                สมัครสมาชิก
              </h1>

              <!-- <div class="bg-secondary rounded p-4 my-4">
                <div class="text-black-50 text-center mb-3">
                  Sign in with
                </div>
                
              </div> -->

              <div class="row">
                <div class="col-12">
                <form>
                  <div class="form-group col-6">
                    <label for="tel">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.email"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="tel">รหัสผ่าน</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password"
                    />
                  </div>
                  <!-- <div class="form-group">
                    <label for="tel">คำนำหน้าชื่อ</label>
                    <b-form-radio
                      v-model="form.prefix"
                      name="some-radios-prefix"
                      value="เด็กชาย"
                      ><p>เด็กชาย</p>
                    </b-form-radio>
                    <b-form-radio
                      v-model="form.prefix"
                      name="some-radios-prefix"
                      value="เด็กหญิง"
                      ><p>เด็กหญิง</p>
                    </b-form-radio>
                  </div> -->
                  <div class="form-group col-6">
                    <label for="tel">ชื่อ</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.firstname"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="tel">นามสุล</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.lastname"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="tel">เพศ</label>
                    <b-form-radio
                      v-model="form.sex"
                      name="some-radios-sex"
                      value="ชาย"
                      ><p>ชาย</p>
                    </b-form-radio>
                    <b-form-radio
                      v-model="form.sex"
                      name="some-radios-sex"
                      value="หญิง"
                      ><p>หญิง</p>
                    </b-form-radio>
                  </div>
                  

                  <div class="form-group col-6">
                    <label for="tel">เบอร์โทรศัพท์</label>
                    <input
                      type="text"
                      class="form-control"
                      name="tel"
                      v-model="form.tel"
                      v-mask="'0##-###-####'"
                    />
                  </div>

                  
                  <div class="form-group col-6">
                    <label for="tel"
                      >ท่านได้รับการประชาสัมพันจากช่องทางใด</label
                    >
                    <b-form-radio
                      v-model="form.know_from"
                      name="some-radios-from"
                      value="ตำรวจประชาสัมพันธ์"
                      ><p>ตำรวจประชาสัมพันธ์</p>
                    </b-form-radio>
                    <b-form-radio
                      v-model="form.know_from"
                      name="some-radios-from"
                      value="Facebook"
                      ><p>Facebook</p>
                    </b-form-radio>
                    <b-form-radio
                      v-model="form.know_from"
                      name="some-radios-from"
                      value="เพื่อนบอกต่อ"
                      ><p>เพื่อนบอกต่อ</p>
                    </b-form-radio>
                    <b-form-radio
                      v-model="form.know_from"
                      name="some-radios-from"
                      value="ป้ายประชาสัมพันธ์"
                      ><p>ป้ายประชาสัมพันธ์</p>
                    </b-form-radio>
                  </div>
                  <div class="row form-group" style="margin-left: 20px;">
                    <div class="col-0.5">
                      <input type="checkbox" />
                    </div>
                    <div class="col">
                      <p style="font-size: 0.8rem;">
                        <u>ยอมรับนโยบายความเป็นความเป็นส่วนตัว</u>
                      </p>
                    </div>
                  </div>

                  <div
                    class="row form-group"
                    style="margin-left: 20px; margin-top: -20px;"
                  >
                    <div class="col-0.5">
                      <input type="checkbox" />
                    </div>
                    <div class="col">
                      <p style="font-size: 0.8rem;">
                        <u>ยอมรับข้อกำหนดและเงื่อนไขในการใช้งาน</u>
                      </p>
                    </div>
                  </div>

                  <button
                    class="btn btn-lg btn-primary btn-block form-group"
                    :disabled="checkingSignIn || otpTimeout > 0"
                    @click.prevent="signIn"
                  >
                    <span style="color: #fff;">
                      <b>สมัครสมาชิก</b>
                    </span>
                  </button>

                  <div class="row form-group">
                    <!-- <div class="col">
                      ----------
                    </div> -->
                    <div class="col text-center">
                      หรือเป็นสมาชิกแล้ว?
                    </div>
                    <!-- <div class="col">
                      ----------
                    </div> -->
                  </div>

                  
                </form>
                <button
                    class="btn btn-lg btn-whitebtn btn-block form-group"
                    style="border: 1px solid #000;"
                    @click="back()"
                  >
                    <span style="color: #202453;">
                      <b>เข้าสู่ระบบ</b>
                    </span>
                  </button>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vSelect from "vue-select";
// import LogoSvg from "../../assets/logo.png";
import { Auth } from "../../models";
import axios from "axios";

export default {
  components: {
    // LogoSvg,
    // "v-select": vSelect,
  },

  data() {
    return {
      showModalDoc: false,
      showModalOTP: false,
      checkingSignIn: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      form: {
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        sex: "",
        nationality: "",
        id_card: "",
        tel: "",
        birth_date: "",
        selectedSchool: null,
        mSchoolId: null,
        know_from: "",
        prefix: ""
      },
      schools: [],
      isAccept: false,
    };
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",
  },
  mounted() {
    this.init();
  },

  methods: {
    
  back() {
    location.href =
              decodeURIComponent(this.$route.query.redirectUrl) || "/";
  },
    async init() {
      var promise = await axios.get(`https://e-learnning-service-temp.yuzudigital.com/m_school?limit=200`);
      console.log(promise.data);
      this.schools = promise.data.data;

      // this.courseForm.lessonList.push({
      //   lesson_name: "",
      //   id: ""
      // })
      // const promise = await Survey.api().findAll(
      //   {
      //     ...additionalParams,
      //     limit: ctx.perPage,
      //     offset: (ctx.currentPage - 1) * ctx.perPage,
      //   },
      //   { save: false }
      // );
    },
    // signin(){
    //   location.href = "/sign-in";
    // },
    async signIn() {
      // this.checkingSignIn = true;

      // let { tel } = this.form;
      if (this.form.tel) {
        this.form.tel = this.form.tel.replace(/-/g, "");
      }

      console.log(this.form);
      // if (this.form.selectedSchool != null) {
      //   this.form.mSchoolId = this.form.selectedSchool.id;
      // }

      if (
        this.form.email != "" &&
        this.form.password != "" &&
        this.form.firstname != "" &&
        this.form.lastname != "" &&
        this.form.sex != ""
      ) {
        try {
          const resultRegister = await Auth.api().register(this.form);

          console.log(resultRegister.response.data);
          if (resultRegister.response.data.response_status == "SUCCESS") {
            this.$toast.success(
              "สมัครสมาชิกสำเร็จ"
            );
            setTimeout(() => {
              location.href = "/sign-in";
            }, 1000);
          } else {
            this.$toast.error(
              resultRegister.response.data.response_description
            );
          }
        } catch (message) {
          this.$toast.error(message);
        } finally {
          this.checkingSignIn = false;
        }
      } else {
        this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.form.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(
          this.form.token,
          this.form.otp_code
        );

        if (valid) {
          Auth.setAuthToken(this.form.authToken);
          Auth.setAuthId(this.form.user_profiles.id);

          this.showModalOTP = false;

          this.$toast.success(
            "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
          );

          setTimeout(() => {
            location.href =
              decodeURIComponent(this.$route.query.redirectUrl) || "/";
          }, 1000);
        }
      } catch (message) {
        console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },
  }
};
</script>
