<template>
  <div class="flex-grow-1 w-100 d-flex align-items-center">
    <div class="bg-composed-wrapper--content py-5">
      <div class="container">
        <div class="row">
          <div
            class="
              col-lg-8
              pl-0
              flex-column
              align-items-start
              justify-content-center
              text-center text-lg-left
              pr-5
            "
            style="background: #A3997E;"
          >

            <img
              src="@/assets/boy_girl.png"
              class="img-fluid d-none d-lg-flex"
            />
          </div>

          <div
            class="
              col-10
              offset-1
              col-lg-4
              offset-lg-0
              pr-0
              d-flex
              flex-column
              justify-content-center
            "
          >
            <b-card class="text-black mt-3 my-4 text-center" style="box-shadow: none;">
              <img
              src="@/assets/logo.png"
              class="img-fluid d-none d-lg-flex"
              style="width: 200px; height: 200px; margin-left: 80px;" 
            />
<br>
              <h1 class="display-3 mb-3 text-primary font-weight-bold">
                เข้าสู่ระบบ
              </h1>
              <p>
                ยินดีต้อนรับเข้าสู่ ติวสอบนายสิบตำรวจ.com<br> กรุณาเข้าสู่ระบบหรือ สมัครสมาชิก
              </p>

              <!-- <div class="bg-secondary rounded p-4 my-4">
                <div class="text-black-50 text-center mb-3">
                  Sign in with
                </div>
                
              </div> -->

              <div>
                <form>
                  <div class="form-group">
                    <label for="tel">E-mail</label>
                    <input
                      type="text"
                      class="form-control"
                      name="tel"
                      v-model="email"
                    />
                  </div>

                  <div class="form-group">
                    <label for="tel">รหัสผ่าน</label>
                    <input
                      type="password"
                      class="form-control"
                      name="tel"
                      v-model="password"
                    />
                  </div>
                  <div class="row form-group">
                    <div class="col-1">
                      <input type="checkbox" />
                    </div>
                    <div class="col" style="margin-left: -20px">
                      จำฉันไว้ในระบบ
                    </div>

                    <div class="col text-right">
                      ลืมรหัสผ่าน?
                    </div>
                  </div>

                  <button
                    class="btn btn-lg btn-primary btn-block form-group"
                    :disabled="checkingSignIn || otpTimeout > 0"
                    @click.prevent="signIn"
                  >
                    <b-spinner
                      v-if="checkingSignIn"
                      label="Loading..."
                    ></b-spinner>
                    <span v-else>
                      {{
                        otpTimeout > 0
                          ? `กรุณารออีก ${otpTimeout} วินาที เพื่อขอ OTP เข้าสู่ระบบใหม่อีกครั้ง`
                          : "เข้าสู่ระบบ"
                      }}
                    </span>
                  </button>

                  <div class="row form-group">
                    <!-- <div class="col">
                      ----------
                    </div> -->
                    <div class="col text-center">
                      หรือ
                    </div>
                    <!-- <div class="col">
                      ----------
                    </div> -->
                  </div>

                  <button
                    class="btn btn-lg btn-whitebtn btn-block form-group"
                    style="border: 1px solid #000;"
                    @click.prevent="register"
                  >
                    <span style="color: #202453;">
                      <b>สมัครสมาชิก</b>
                    </span>
                  </button>
                </form>
              </div>
            </b-card>

            <b-modal
              v-model="showModalOTP"
              id="otp-modal"
              centered
              hide-footer
              hide-header
            >
              <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
              <b-container>
                <b-row>
                  <b-col>
                    <h5 class="display-3 text-primary font-weight-bold">
                      กรอกรหัส OTP
                    </h5>

                    <p class="text-black-50">
                      รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข {{ form.tel }}
                    </p>

                    <form>
                      <div class="form-group">
                        <label for="otp_code">รหัส OTP</label>
                        <input
                          type="text"
                          class="form-control"
                          name="otp_code"
                          v-model="form.otp_code"
                        />
                      </div>

                      <button
                        class="btn btn-lg btn-primary btn-block"
                        :disabled="checkingOTP"
                        @click.prevent="validateOTP"
                      >
                        <b-spinner
                          v-if="checkingOTP"
                          label="Loading..."
                        ></b-spinner>
                        <span v-else>ยืนยัน</span>
                      </button>
                    </form>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>

            <button
              class="btn btn-lg btn-danger btn-block"
              @click="showModalDoc = true"
            >
              ดูคู่มือการใช้งาน E-Learning
            </button>

            <b-row class="mt-3 text-center">
              <b-col>
                แจ้งปัญหา/ขอความช่วยเหลือเกี่ยวกับระบบได้ที่
                <a target="_blank" href="http://line.me/ti/p/@858xnebk"
                  >http://line.me/ti/p/@858xnebk</a
                >
              </b-col>
            </b-row>

            <b-modal
              v-model="showModalDoc"
              id="doc-modal"
              size="xl"
              centered
              hide-footer
              hide-header
            >
              <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
              <b-container>
                <b-row class="flex-sm-column-reverse flex-md-row">
                  <b-col
                    lg="5"
                    class="d-flex flex-column justify-content-between"
                  >
                    <h5
                      class="
                        display-3
                        mt-sm-4 mt-md-2
                        mb-3
                        text-primary
                        font-weight-bold
                      "
                    >
                      คู่มือการใช้งานระบบฐานข้อมูล D.A.R.E.
                    </h5>

                    <div>
                      <h5 class="display-5 mb-2 text-primary font-weight-bold">
                        ดาวน์โหลดเอกสาร
                      </h5>

                      <div>
                        <u>
                          <a
                            href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
                            target="_blank"
                            >ไฟล์เอกสาร PDF</a
                          >
                        </u>
                      </div>

                      <!-- <div>
                        <u>
                          <a href="http://" target="_blank"
                            >ไฟล์เอกสาร PowerPoint</a
                          >
                        </u>
                      </div> -->
                    </div>
                  </b-col>
                  <b-col lg="7" class="mt-md-2">
                    <div class="video-container">
                      <!-- <iframe
                        width="1537"
                        height="872"
                        src="https://www.youtube.com/embed/RmuL-BPFi2Q?list=RDRmuL-BPFi2Q"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe> -->
                      <b-embed
                        src="https://www.youtube.com/embed/vmd2KaTElDE"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      >
                      </b-embed>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LogoSvg from "../../assets/logo.svg";
import { Auth } from "../../models";

export default {
  components: {
    // LogoSvg,
  },

  data() {
    return {
      showModalDoc: false,
      showModalOTP: false,
      checkingSignIn: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      email: '',
      password: '',
      form: {
        tel: "",
        authToken: "",
        token: "",
        user_profiles: null,
        otp_code: "",
      },
    };
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",
  },

  methods: {
    register() {
      location.href = "/register";
    },
    async signIn() {
      // this.checkingSignIn = true;

      // let { tel } = this.form;

      // tel = tel.replace(/-/g, "");

      try {
        const resultLogin = await Auth.api().signIn({
          email: this.email,
          password: this.password,
        });

        console.log(resultLogin)

        Auth.setAuthToken(resultLogin.token);
        Auth.setAuthId(resultLogin.user.id);

        this.$toast.success(
          "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
        );

        setTimeout(() => {
          location.href =
            "/";
        }, 1000);
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.checkingSignIn = false;
      }
      // Auth.setAuthToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiZmlyc3RfbmFtZSI6IlRBTyIsImxhc3RfbmFtZSI6IlRBTyIsInBvc2l0aW9uX25hbWUiOiJBRE1JTiIsImlkX2NhcmQiOm51bGwsImlkX2dvdmVybm1lbiI6bnVsbCwiYmlydGhfZGF0ZSI6bnVsbCwicmV0aXJlX3llYXIiOm51bGwsImFkZHJlc3MiOm51bGwsImFkZHJlc3NfZG9jdW1lbnQiOm51bGwsInBob25lX251bWJlciI6IjA5OTA1Njk1OTUiLCJlbWFpbCI6bnVsbCwidXNlcl9uYW1lIjpudWxsLCJtb2RlbF9ubyI6bnVsbCwibW9kZWxfeWVhciI6bnVsbCwidXNlcl9zdGF0dXMiOiLguYDguILguYnguLLguKrguK3guJkiLCJ1c2VyX2FwcHJvdmUiOm51bGwsImNyZWF0ZV9ieSI6bnVsbCwidXBkYXRlX2J5IjpudWxsLCJwYXNzaGFzaCI6bnVsbCwidXNlcl90eXBlIjoiU1VQRVJfQURNSU4iLCJhZG1pbl90eXBlIjpudWxsLCJiZF9kYXRlIjpudWxsLCJ0b3RhbF90aW1lIjo5MSwibWVkYWwiOiLguKPguLDguJTguLHguJrguJXguYnguJkiLCJjcmVhdGVkQXQiOiIyMDIxLTEwLTE5VDAyOjM3OjMyLjk4NloiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTI4VDAzOjU4OjMzLjc2MVoiLCJ1c2VyR3JvdXBJZCI6bnVsbCwibVJhbmtJZCI6bnVsbCwibUhlYWRxdWFydGVySWQiOm51bGwsIm1EaXZpc2lvbklkIjpudWxsLCJtU3RhdGlvbklkIjpudWxsLCJpYXQiOjE2MzgxMjU2MTZ9.nw1yJMu9w_68B432P23STbiHba0tNriVrsw4VtfGBMU');
      //     Auth.setAuthId(9);

      //     this.showModalOTP = false;

      //     this.$toast.success(
      //       "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
      //     );

      //     setTimeout(() => {
      //       location.href =
      //         decodeURIComponent(this.$route.query.redirectUrl) || "/";
      //     }, 1000);
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.form.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(
          this.form.token,
          this.form.otp_code
        );

        if (valid) {
          Auth.setAuthToken(this.form.authToken);
          Auth.setAuthId(this.form.user_profiles.id);

          this.showModalOTP = false;

          this.$toast.success(
            "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
          );

          setTimeout(() => {
            location.href = "/";
          }, 1000);
        }
      } catch (message) {
        console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },
  },
};
</script>
